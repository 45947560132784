const formatDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('es-ES', options);
    
    return formattedDate;
  };
  
  // Método para obtener solo el día de la semana, el número y el mes
  formatDate.getFecha = (dateString) => {
    const options = { weekday: 'long', day: 'numeric', month: 'long' };
    
    const formattedReducedDate = new Date(dateString).toLocaleDateString('es-ES', options);
    let capitalizedDate = formattedReducedDate.charAt(0).toUpperCase() + formattedReducedDate.slice(1);
    
    return capitalizedDate;
};
  


/////////// CAMBIAR CUANDO CAMBIE EL HORARIO !!!!!!!!!!!!!!!!


formatDate.getHora = (dateString) => {
  console.log(dateString, 'datestringgg');

  const originalDate = new Date(dateString);

  // Sumar 5 horas en UTC
  originalDate.setUTCHours(originalDate.getUTCHours() + 2);

  // Mostrar SIEMPRE en UTC (todos ven lo mismo)
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: 'UTC', // 🔒 Fijamos la zona
  };

  const formattedHour = originalDate.toLocaleTimeString('es-ES', options);

  // Limpiar formato: '3:45 p. m.' → '3:45PM'
  const capitalizedHour = formattedHour.replace(/\./g, '').toUpperCase();
  const finalHour = capitalizedHour.slice(0, -2) + capitalizedHour.slice(-1);

  return finalHour;
};



  

  function isLessThan10HoursRemaining(lessonDate) {
    const currentDateTime = new Date();
    const lessonDateTime = new Date(lessonDate);
  
    // Calcula la diferencia en milisegundos
    const differenceInMs = lessonDateTime - currentDateTime;
  
    // Convierte la diferencia de milisegundos a horas
    const differenceInHours = differenceInMs / (1000 * 60 * 60);
  
    // Verifica si faltan menos de 10 horas
    return differenceInHours < 10;
  };
  
export default {
  formatDate,
  isLessThan10HoursRemaining
}